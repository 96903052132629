import {Dispatch, createContext} from 'react';
import {User} from '../../types/user.type';

export const initialState = {};
interface IContext {
  user: User;
  userDispatch: Dispatch<any>;
}

const UserContext = createContext({} as IContext);

export default UserContext;
