import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import {Bolt, EventNote} from '@mui/icons-material';
import {Campaign} from '../../../core/types/campaign.type';
import LoadingScreen from '../../components/LoadingScreen';
import UserContext from '../../../core/state/context/user.context';
import GetCampaigns from '../../../core/services/GetCampaigns';
import GetDateFromISOString from '../../../core/helpers/GetDateFromISOString';
import GetDaysBetween from '../../../core/helpers/GetDaysBetween';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

export default function HomePage() {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);

  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const handleCreateCampaign = () => () => {
    navigate(`/create-campaign`);
  };

  useEffect(() => {
    setLoading(true);
    const prepareData = async () => {
      const token = localStorage.getItem('voop_token');
      console.log('user.userId: ', user.userId);
      const result = await GetCampaigns(ENDPOINT, token, user.userId as string);
      setCampaigns(result);
      setLoading(false);
    };
    prepareData();
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="home-dashboard">
        <div className="home-dashboard__top-row">
          <div className="home-dashboard__top-row-left">
            <h1 className="home-dashboard__title">Hi {user.email}</h1>
            <p>Start connecting to your community</p>
          </div>

          <div className="home-dashboard__top-row-right">
            {campaigns && campaigns.length > 0 && (
              <>
                <Button
                  className="voop-button"
                  variant="contained"
                  onClick={handleCreateCampaign()}
                >
                  Create a new campaign
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="pt-16">
          {campaigns.length === 0 && (
            <div className={'campaign-box-container'}>
              <div className="campaign-box">
                <div className="flex flex-col">
                  <p
                    className="font-dm-sans text-3xl font-normal
                  leading-9 tracking-tighter pb-10"
                  >
                    Create your first <br /> Voop campaign
                  </p>
                  <div>
                    <Button
                      className="voop-button"
                      variant="contained"
                      onClick={handleCreateCampaign()}
                    >
                      Get started
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={'campaign-box-container'}>
            {campaigns.length > 0 &&
              campaigns.map((campaign: any, index: any) => {
                return (
                  <Link
                    key={index}
                    className="campaign-box__link"
                    to={`/campaigns/${campaign.uuid}`}
                  >
                    <div className={'campaign-box'}>
                      <p className="campaign-box__details">
                        <Bolt className="campaign-box__live" /> Live | Created{' '}
                        {campaign && campaign.Created
                          ? GetDateFromISOString(campaign.Created)
                          : ''}
                      </p>
                      <div className="h-[100px] md:h-[150px] overflow-hidden">
                        <p className="campaign-box__title line-clamp-2">
                          {campaign.question}
                        </p>
                      </div>
                      <p className="campaign-box__details-2">
                        <EventNote className="campaign-box__details-2-icon" />{' '}
                        {campaign && campaign.startDate && campaign.endDate
                          ? GetDaysBetween(campaign.startDate, campaign.endDate)
                          : 0}{' '}
                        days remaining
                      </p>

                      <br />
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
