import {createApiRequest} from '../api/api';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

type TokenValidation = {
  token: string;
};

export default async function PostTokenValidation(
  tokenValidation: TokenValidation,
) {
  const headers = {
    'Content-Type': 'application/json',
  };
  const body = {
    token: tokenValidation.token,
  };
  const result = await createApiRequest(
    JSON.stringify(body),
    headers,
    'POST',
    `${ENDPOINT}/token/validate`,
  );

  return result;
}
