import {UPDATEUSER} from '../../types/user.type';

const UserReduer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATEUSER:
      return action.payload;
    default:
      return state;
  }
};

export default UserReduer;
