import React from 'react';

type Props = {};

function DetailsTab({}: Props) {
  return (
    <div className="w-[400px] pt-10">
      <p className="pt-4 pb-1 text-sm">First Name</p>
      <input className="input text-base"></input>
      <p className="pt-4 pb-1 text-sm">Last Name</p>
      <input className="input text-base"></input>
      <p className="pt-4 pb-1 text-sm">Email Address</p>
      <input className="input text-base"></input>
      <div className="pt-5">
        <button className="voop-button">Save Changes</button>
      </div>
    </div>
  );
}

export default DetailsTab;
