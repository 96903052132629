import {createFormApiRequest} from '../api/api';

export default async function PostVoop(
  endpoint: string,
  audioBlob: Blob,
  campaignId: string,
  browser: string,
) {
  return await createFormApiRequest(
    audioBlob,
    {},
    'POST',
    `${endpoint}/campaign/${campaignId}/voop?browser=${browser}&type=audio`,
  );
}
