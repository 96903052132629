import {Campaign} from '../types/campaign.type';
import {createApiRequest} from '../api/api';

export default async function getCampaign(
  endpoint: string,
  token: any,
  userId: string,
  campaignId: string,
): Promise<Campaign> {
  // todo want there to be an api response (stripped back version of the object
  // todo add this in
  const headers = {
    Authorization: 'Bearer ' + token,
  };
  const data = await createApiRequest(
    null,
    headers,
    'GET',
    `${endpoint}/user/${userId}/campaign/${campaignId}`,
  );
  console.log('data', data);
  // todo maybe do the check here for if empty or error and throw an error
  return JSON.parse(data.body);
}
