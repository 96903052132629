import {useState, useContext} from 'react';
import {Tabs, Tab} from '@mui/material';
import CustomTabPanel from './CustomTabPanel';
import DetailsTab from './DetailsTab';
import PasswordTab from './PasswordTab';
import VoopsTab from './VoopsTab';
import BrandingTab from './BrandingTab';
import UserContext from '../../../core/state/context/user.context';

export default function AccountPage() {
  const {user} = useContext(UserContext);
  const [voops, setVoops] = useState<[]>([]);
  const [value, setValue] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="account-page pt-[100px] px-[40px]">
      <h1 className="text-[40px] pb-4">Account Settings</h1>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab label="Your Details" className="tab" />
        <Tab label="Password" className="tab" />
        <Tab label="Branding" className="tab" />
        <Tab label="Voops" className="tab" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <DetailsTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PasswordTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <BrandingTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <VoopsTab voops={voops} />
      </CustomTabPanel>
    </div>
  );
}
