import React from 'react';
import Modal from '@mui/material/Modal';
import Card from '../Card';
import {Close, AutoAwesome} from '@mui/icons-material';

type Props = {
  isOpen: boolean;
  toggleModal: (topInsight: string) => void;
  topInsight: string;
  title?: string;
};

function TopInsightModal({
  isOpen,
  toggleModal,
  topInsight,
  title = 'Top Insight',
}: Props) {
  return (
    <div>
      <Modal open={isOpen} onClose={toggleModal}>
        <Card
          className="absolute top-1/2 left-1/2 transform-translate-50-percent -translate-x-1/2 -translate-y-1/2
    w-400 py-5 px-10 w-4/5 md:w-[650px] max-h-96 md:max-h-full overflow-y-auto"
        >
          <Close
            className="absolute top-5 right-5 cursor-pointer"
            onClick={() => toggleModal('')}
          />

          <p className="text-base pb-6">
            <AutoAwesome className="mr-2" />
            {title}
          </p>

          <p className="pb-5 text-sm md:text-2xl">{topInsight}</p>
        </Card>
      </Modal>
    </div>
  );
}

export default TopInsightModal;
