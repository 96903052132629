import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAudioRecorder} from 'react-audio-voice-recorder';
import RecordingButton from '../../components/RecordingButton';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import CircularProgress from '@mui/material/CircularProgress';
import GetCampaign from '../../../core/services/GetCampaign';
import PostVoop from '../../../core/services/PostVoop';
import {Campaign} from '../../../core/types/campaign.type';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

export default function RecordVoopPage() {
  const token = localStorage.getItem('voop_token');
  const routeParams = useParams();
  const campaignId = routeParams.campaignId;

  const [campaign, setCampaign] = useState<Campaign>(null!);
  // TODO: Update logoUri based on data from the backend when the page loads
  const [logoUri, setLogoUri] = useState<any>(null);
  const [browser, setBrowser] = useState('other');
  const [sentiment, setSentiment] = useState(null!);
  const [processing, setProcessing] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (campaignId) {
      GetCampaign(ENDPOINT, token, 'VOOP_USER', campaignId).then(
        (response: Campaign) => {
          console.log('campaign', response);
          setCampaign(response);
        },
      );
    }
  }, []);

  const [recording, setRecording] = useState<any>(null);
  const [blob, setBlob] = useState<any>(null);
  const addAudioElement = (blob: any) => {
    console.log('done');
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    console.log(audio);
    setRecording(audio.src);
    setBlob(blob);
  };

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
  } = useAudioRecorder({noiseSuppression: true, echoCancellation: true});

  useEffect(() => {
    if (!recordingBlob) return;
    console.log('recording blob', recordingBlob);
    // Create blob URL
    console.log(URL.createObjectURL(recordingBlob));

    addAudioElement(recordingBlob);
  }, [recordingBlob]);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf('Firefox') > -1) {
      console.log('firefox');
      setBrowser('firefox');
    } else if (
      userAgent.indexOf('Safari') > -1 &&
      userAgent.indexOf('Chrome') === -1
    ) {
      console.log('safari');
      setBrowser('safari');
    } else {
      console.log('other');
      setBrowser('other');
    }
  }, []);

  const processVoop = async () => {
    if (!campaignId) return;

    setProcessing(true);
    const responseData: any = await PostVoop(
      ENDPOINT,
      blob,
      campaignId,
      browser,
    );
    console.log('response data:', responseData);
    setSentiment(responseData.sentiment.Sentiment);
    setProcessing(false);
    setDone(true);
  };

  const reRecordAudio = () => {
    setRecording(null);
    startRecording();
  };

  return (
    <>
      <div className="gallery-page voop-feedback">
        {done && (
          <div className="voop-feedback flex flex-col w-full h-full items-center px-5 py-20">
            {logoUri && <img alt="Logo" src={logoUri} className="h-[100px]" />}
            {!logoUri && (
              <p className="voop-feedback__partner-logo">Partner Logo</p>
            )}

            <div className="flex flex-col h-full justify-center items-center text-center">
              {sentiment === 'NEGATIVE' ? (
                <p className="text-4xl px-5 pb-4">Sorry to hear that.</p>
              ) : (
                <p className="text-4xl px-5 pb-4">Thanks for your feedback!</p>
              )}

              <p className="text-lg px-5">
                We&apos;ve sent your recording to your community manager
              </p>
            </div>
          </div>
        )}

        {!done && (
          <>
            {processing && (
              <div
                className="fixed w-full h-full bg-slate-900 bg-opacity-50
              flex justify-center items-center z-50"
              >
                <CircularProgress size={100} />
              </div>
            )}

            <div className="voop-feedback flex flex-col items-center px-5 py-20">
              {logoUri && (
                <img
                  alt="Logo"
                  src={logoUri}
                  className="h-[70px] xs:h-[100px]"
                />
              )}
              {!logoUri && (
                <p className="voop-feedback__partner-logo">Your Logo</p>
              )}

              <p className="text-center text-1xl xs:text-4xl font-medium leading-normal tracking-tighter pt-5 xs:pt-10">
                {campaign && campaign.question ? campaign.question : ''}
              </p>

              <p className="text-base font-normal leading-6 tracking-tight pt-5 xs:pt-10 pb-5 xs:pb-10">
                {!isRecording && !recordingBlob && 'Tap to start recording'}
                {isRecording && 'Tap again to stop recording'}
                {!isRecording && recordingBlob && (
                  <>
                    {'Send or '}
                    <span
                      onClick={reRecordAudio}
                      className="text-voop-links cursor-pointer"
                    >
                      re-record message
                    </span>
                  </>
                )}
              </p>

              {browser === 'safari' && (
                <RecordingButton
                  isRecording={isRecording}
                  recordingTime={recordingTime}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  recordingBlob={recordingBlob}
                  processVoop={processVoop}
                />
              )}
              {browser === 'other' && (
                <RecordingButton
                  isRecording={isRecording}
                  recordingTime={recordingTime}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  recordingBlob={recordingBlob}
                  processVoop={processVoop}
                />
              )}
              {browser === 'firefox' && (
                <RecordingButton
                  isRecording={isRecording}
                  recordingTime={recordingTime}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  recordingBlob={recordingBlob}
                  processVoop={processVoop}
                />
              )}

              {!recordingBlob && !isRecording && (
                <p
                  className="text-blue-500 text-center font-dm-sans pt-10
                text-base font-semibold leading-snug tracking-tight"
                >
                  Type a note instead
                </p>
              )}

              {!isRecording && recordingBlob && (
                <div className="pt-5 xs:pt-9">
                  <p className="text-center text-base font-normal leading-snug tracking-tight pb-4">
                    Listen to your recording
                  </p>

                  <AudioPlayer recording={recording} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className="fixed bottom-0 left-0 right-0 p-4 text-white text-center
              flex flex-col justify-center items-center"
      >
        <div>
          <img
            alt="Voop Logo"
            src={`${process.env.REACT_APP_BASE_URL}/voop-logo.svg`}
          />
        </div>
        <p className="text-gray-600 text-center font-dm-sans text-base font-normal leading-normal">
          By continuing, you agree to our
          <br />
          <span className="text-voop-white">Terms of Service</span> and{' '}
          <span className="text-voop-white">Privacy Policy</span>
        </p>
      </div>
    </>
  );
}
