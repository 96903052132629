import {useState, useContext} from 'react';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import {Input} from '@mui/material';
import {LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {BoltOutlined} from '@mui/icons-material';
import PostCampaign from '../../../core/services/PostCampaign';
import UserContext from '../../../core/state/context/user.context';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

export default function CreateCampaignPage() {
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [question, setQuestion] = useState('');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [createCampaignPage, setCreateCampaignPage] = useState<any>(1);

  /**
   * Create Campaign
   */
  const handleCreateCampaign = async () => {
    const campaignData = {
      question,
      startDate,
      endDate,
    };

    console.log('campaignData', campaignData);

    if (
      !campaignData.question ||
      !campaignData.startDate ||
      !campaignData.endDate
    ) {
      alert('Please fill in all fields');
      return;
    } else {
      setSubmitting(true);

      const token = localStorage.getItem('voop_token');

      console.log('user: ', user);

      console.log('creating campaign as', user.userId);
      const response = await PostCampaign(
        ENDPOINT,
        token,
        user.userId as string,
        campaignData,
      );

      console.log('response: ', response);

      if (response !== 'error') {
        alert(
          `Campaign created successfully! Your link: ${process.env.REACT_APP_BASE_URL}/voop-campaigns/${response}`,
        );
        navigate('/');
      } else {
        alert('There was an error creating your campaign');
      }
      setSubmitting(false);
    }
  };

  const handleChangeCampaignQuestion = (event: any) => {
    setQuestion(event.target.value);
  };

  const formatDate = (date: Date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(date.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  };

  const handleChangeStartDate = (event: any) => {
    setStartDate(formatDate(event.$d));
  };

  const handleChangeEndDate = (event: any) => {
    setEndDate(formatDate(event.$d));
  };

  const handleChangePage = (page: number) => {
    if (page === 1 && !question) {
      alert('Please fill in all fields');
      return;
    }

    setCreateCampaignPage(page + 1);
  };

  return (
    <>
      <div className="create-campaign-dashboard">
        <div className="create-campaign-dashboard__inner-wrapper">
          <div className="create-campaign-dashboard__left">
            <div className="max-w-3xl">
              <div className="create-campaign-dashboard__paged">
                {createCampaignPage === 1 && (
                  <div className={'create-campaign-dashboard__paged-1'}>
                    <p className="create-campaign-dashboard__title">
                      What would you like to ask your community?
                    </p>
                    <p className="text-lg pt-11">Type your question here</p>
                    <Input
                      required
                      className="create-campaign-dashboard__input"
                      style={{backgroundColor: 'white'}}
                      onChange={(event: any) => {
                        handleChangeCampaignQuestion(event);
                      }}
                    />
                  </div>
                )}

                {createCampaignPage === 2 && (
                  <div className={'create-campaign-dashboard__paged-2'}>
                    <p className="create-campaign-dashboard__title">
                      Set up a schedule for your Voop campaign
                    </p>
                    <p className="text-sm pt-6 pb-1">Start Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        className="date-calendar"
                        format="DD-MM-YYYY"
                        onChange={(event: any) => handleChangeStartDate(event)}
                      />
                    </LocalizationProvider>

                    <br />
                    <br />

                    <p className="text-sm pb-1">End Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        className="date-calendar"
                        format="DD-MM-YYYY"
                        onChange={(event: any) => handleChangeEndDate(event)}
                      />
                    </LocalizationProvider>

                    <br />

                    <span className={submitting ? '' : 'hidden'}>
                      loading...
                    </span>
                  </div>
                )}

                <br />

                <div>
                  {createCampaignPage < 2 && (
                    <Button
                      className="voop-button"
                      disabled={submitting}
                      variant="contained"
                      onClick={() => {
                        handleChangePage(1);
                      }}
                    >
                      Next
                    </Button>
                  )}
                  {createCampaignPage === 2 && (
                    <Button
                      className="voop-button"
                      disabled={submitting}
                      variant="contained"
                      onClick={() => {
                        void handleCreateCampaign();
                      }}
                    >
                      Create this Campaign
                    </Button>
                  )}

                  {createCampaignPage === 1 && (
                    <div className="create-campaign-dashboard__suggested-topics">
                      <p>
                        <BoltOutlined /> Suggested Topics to ask about
                      </p>

                      <div className="create-campaign-dashboard__suggested-topics-grid">
                        <div className="create-campaign-dashboard__suggested-topics-grid-column">
                          <p>Gameplay</p>
                          <p>Tournaments</p>
                          <p>Leaderboard</p>
                        </div>
                        <div className="create-campaign-dashboard__suggested-topics-grid-column">
                          <p>Events</p>
                          <p>Bugs</p>
                          <p>Balancing</p>
                        </div>
                        <div className="create-campaign-dashboard__suggested-topics-grid-column">
                          <p>Maps</p>
                          <p>Community Experience</p>
                          <p>Onboarding</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="create-campaign-dashboard__right">
            <p className="text-sm">What your community will see</p>

            <div className="create-campaign-dashboard-mobile-container">
              <img
                className="create-campaign-dashboard-mobile"
                alt=""
                src="mobile-view.png"
              />
              <div className="create-campaign-dashboard__fixed-title">
                <p className="text-xs sm:text-sm md:text-base">{question}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
