import {createFormApiRequest} from '../api/api';

export default async function PostLogo(
  endpoint: string,
  token: any,
  userId: string,
  imageData: any,
) {
  const headers = {
    accessToken: token,
  };
  console.log(token);
  const data = await createFormApiRequest(
    imageData,
    headers,
    'POST',
    `${endpoint}/user/${userId}/logo`,
  );
  console.log('data', data);
  return data;
}
