import CircleIcon from '../../assets/icons/CircleIcon';

type Props = {
  icon: React.ReactNode;
  description: string;
};

function EmptyInsight({icon, description}: Props) {
  return (
    <div className="flex justify-center items-center w-auto h-5/6">
      <div className="relative flex justify-center items-center mr-6">
        <CircleIcon width={126} height={126} fill="#242932" />
        {icon}
      </div>
      <p className="greyed-out max-w-md">{description}</p>
    </div>
  );
}

export default EmptyInsight;
