import {createApiRequest} from '../api/api';

export default async function PostCampaign(
  endpoint: string,
  token: any,
  userId: string,
  campaign: any,
) {
  const headers = {
    Authorization: 'Bearer ' + token,
  };
  console.log(token);
  const data = await createApiRequest(
    campaign,
    headers,
    'POST',
    `${endpoint}/user/${userId}/campaign`,
  );
  console.log('data', data);

  return data;
}
