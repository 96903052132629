import React from 'react';
import LogoLoader from '../../assets/images/logo-loader.svg';

type Props = {};

function LoadingScreen({}: Props) {
  return (
    <div
      className="flex h-full w-full justify-center items-center fixed z-50
    bg-[#181C24] bg-opacity-100"
    >
      <img src={LogoLoader} className="animate-pulse" />
    </div>
  );
}

export default LoadingScreen;
