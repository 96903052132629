import React from 'react';

type Props = {
  genderWeightings: {
    female: string;
    male: string;
  };
};

function GenderBar({genderWeightings}: Props) {
  return (
    <div>
      <div className="flex h-4 md:h-7 w-full rounded-full">
        <div
          style={{width: `${genderWeightings.male}`}}
          className="h-full rounded-s-full border-2 border-r-[1px]
      bg-[#5372ff] border-[#5372ff] bg-opacity-30"
        />
        <div
          style={{width: `${genderWeightings.female}`}}
          className="h-full rounded-e-full border-2 border-l-[1px]
          bg-[#fde967] border-[#fde967] bg-opacity-30"
        />
      </div>
    </div>
  );
}

export default GenderBar;
