export default function GetDaysBetween(
  startDateStr: string,
  endDateStr: string,
) {
  if (!startDateStr || !endDateStr) return 0;
  // Parse the strings as dates
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const diffInMs = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const diffInDays = diffInMs / (24 * 60 * 60 * 1000);

  return Math.round(diffInDays);
}
