import {useEffect, useState, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import {Bolt, Share} from '@mui/icons-material';
import LoadingScreen from '../../components/LoadingScreen';
import Summary from './Summary';
import DataInsights from './DataInsights';
import TopInsights from './TopInsights';
import ShareVoopModal from '../../components/modals/ShareVoopModal';
import {CampaignInsights} from '../../../core/types/campaignInsights.type';
import {GetCampaignInsights} from '../../../core/services/GetCampaignInsights';
import GetCampaign from '../../../core/services/GetCampaign';
import UserContext from '../../../core/state/context/user.context';
import GetDateFromISOString from '../../../core/helpers/GetDateFromISOString';
import GetDaysBetween from '../../../core/helpers/GetDaysBetween';
import {Campaign} from '../../../core/types/campaign.type';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;
/**
 * Campaign Screen
 *
 * @constructor
 */
export default function CampaignPage() {
  const routeParams = useParams();
  const campaignId = routeParams.campaignId;
  const {user} = useContext(UserContext);

  const [campaign, setCampaign] = useState<Campaign>(null!);
  const [campaignInsights, setCampaignInsights] = useState<CampaignInsights>(
    null!,
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isCampaignReady, setIsCampaignReady] = useState<boolean>(false);
  const [isCampaignInsightsReady, setIsCampaignInsightsReady] =
    useState<boolean>(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);

  const toggleShareModal = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };

  const token = localStorage.getItem('voop_token');

  useEffect(() => {
    if (campaignId) {
      GetCampaign(ENDPOINT, token, user.userId as string, campaignId).then(
        (response: Campaign) => {
          setCampaign(response);
          setIsCampaignReady(true);
        },
      );
      GetCampaignInsights(
        ENDPOINT,
        token,
        user.userId as string,
        campaignId,
      ).then((response: CampaignInsights) => {
        setCampaignInsights(response);
        setIsCampaignInsightsReady(true);
      });
    }
  }, []);

  useEffect(() => {
    if (isCampaignReady && isCampaignInsightsReady) {
      setLoading(false);
    }
  }, [isCampaignReady, isCampaignInsightsReady]);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="dashboard campaign-dashboard">
        <div className="row px-9">
          <div className="sub-row pt-10">
            <Link
              className="font-medium text-lg leading-none tracking-widest"
              to={'/'}
            >
              &#60; Back to Campaigns
            </Link>
          </div>
          <div className="sub-row pt-14">
            <h1 className="text-3xl md:text-4xl">{campaign?.question}</h1>
          </div>
          <div className="flex flex-col md:flex-row justify-between py-5">
            <div className="flex flex-row flex-wrap my-5 gap-y-2 md:justify-center md:items-center">
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs">
                  <Bolt className="campaign-box__live" /> {' Live'}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs pr-1">
                  Created{' '}
                  {campaign && campaign.Created
                    ? GetDateFromISOString(campaign.Created)
                    : ''}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs pr-1">
                  {campaign && campaign.startDate && campaign.endDate
                    ? GetDaysBetween(campaign.startDate, campaign.endDate)
                    : 0}{' '}
                  {'days remaining'}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <p className="text-xs pr-1">
                  {!campaignInsights || !campaignInsights.totalCampaignVoops
                    ? '0 Voop'
                    : campaignInsights.totalCampaignVoops > 1
                    ? `${campaignInsights.totalCampaignVoops} Voops`
                    : `${campaignInsights.totalCampaignVoops} Voop`}
                </p>
              </div>
              <div className="pr-2 mr-2 border-r-2 h-4 flex justify-center items-center">
                <a href="/" className="text-xs pr-1">
                  {'Edit this campaign'}
                </a>
              </div>
              <div className="pr-2 mr-2 h-4 flex justify-center items-center">
                <a href="/" className="text-xs pr-1">
                  {'Export'}
                </a>
              </div>
            </div>
            <div className="column flex gap-6">
              <Link
                to={`/campaigns/${campaignId}/voops`}
                className="voop-button h-8 xs:h-full"
                onClick={toggleShareModal}
              >
                <span className="text-xs md:text-base">View Voops</span>
              </Link>

              <Button
                className="voop-button h-8 xs:h-full"
                onClick={toggleShareModal}
              >
                <Share className="mr-2" />{' '}
                <span className="text-xs md:text-base">
                  {'Share this Voop campaign'}
                </span>
              </Button>
            </div>
          </div>
        </div>

        {/* row2 */}
        <Summary campaignInsights={campaignInsights} />

        {/* row3 */}
        <TopInsights
          campaignInsights={campaignInsights}
          campaignId={campaignId}
        />

        {/* row4 */}
        <DataInsights campaignInsights={campaignInsights} />

        <ShareVoopModal
          isOpen={isOpenShareModal}
          toggleModal={toggleShareModal}
          url={location.origin + '/voop-campaigns/' + campaignId}
        />
      </div>
    </>
  );
}
