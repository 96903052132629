import {Key, useState} from 'react';
import EmptyInsight from '../../components/EmptyInsight';
import {SparklesIcon} from '@heroicons/react/24/outline';
import {AutoAwesome} from '@mui/icons-material';
import TopInsightModal from '../../components/modals/TopInsightModal';
import {CampaignInsights} from '../../../core/types/campaignInsights.type';

type Props = {
  campaignInsights: CampaignInsights;
  campaignId: string | undefined;
};

function TopInsights({campaignInsights}: Props) {
  const [isOpenTopInsightModal, setIsOpenTopInsightModal] =
    useState<boolean>(false);
  const [topInsight, setTopInsight] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');

  const toggleTopInsightModal = (insight: any) => {
    setModalTitle('Insight');
    setTopInsight(insight);
    setIsOpenTopInsightModal(!isOpenTopInsightModal);
  };

  const toggleSuggestedActionModal = (suggestedAction: any) => {
    setModalTitle('Suggested Action');
    setTopInsight(suggestedAction);
    setIsOpenTopInsightModal(!isOpenTopInsightModal);
  };

  return (
    <div className="row row3 px-9 pt-5 pb-10">
      <h2>Top insights</h2>
      <div className="grid-box insight-page-override">
        {!campaignInsights || !campaignInsights.topInsights ? (
          <div className="w-full h-72">
            <EmptyInsight
              icon={<SparklesIcon className="absolute h-20 w-20" />}
              description="Insights will appear here once you have some Voop responses"
            />
          </div>
        ) : (
          <div className="flex flex-row pt-10 overflow-y-scroll gap-5">
            {campaignInsights.topInsights.map((insight: any, index: any) => {
              console.log('[[[insight]]]', insight);
              return (
                <div
                  key={index}
                  className="cursor-pointer flex"
                  onClick={() => toggleTopInsightModal(insight['top-insight'])}
                >
                  <div className="campaign-box insight-box flex flex-col">
                    <p className="text-base pb-6">
                      <AutoAwesome className="mr-2" />
                      Insight
                    </p>
                    <p className="overflow-y-auto mb-5">
                      {insight['top-insight']}
                    </p>

                    {insight.keywords ? (
                      <div className="flex flex-row gap-3 mt-auto flex-wrap">
                        {insight.keywords
                          .split(', ') // limit to 2 keywords
                          .slice(0, 2)
                          .map(
                            (
                              keyword: string,
                              index: Key | null | undefined,
                            ) => {
                              return (
                                <div
                                  className="text-slate-700 text-sm font-bold tracking-tight bg-[#F8F8FF] rounded-full px-4 py-2 whitespace-nowrap flex items-center"
                                  key={index}
                                >
                                  {keyword}
                                </div>
                              );
                            },
                          )}
                      </div>
                    ) : null}

                    {insight['suggested-action'] ? (
                      <p
                        className="text-slate-200 text-base font-normal leading-snug mt-4 hover:underline"
                        onClick={event => {
                          event.stopPropagation();
                          toggleSuggestedActionModal(
                            insight['suggested-action'],
                          );
                        }}
                      >
                        Suggested Action
                      </p>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <TopInsightModal
        isOpen={isOpenTopInsightModal}
        toggleModal={toggleTopInsightModal}
        topInsight={topInsight}
        title={modalTitle}
      />
    </div>
  );
}

export default TopInsights;
