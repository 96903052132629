import {CampaignInsights} from '../../core/types/campaignInsights.type';

type Props = {
  ageRangeWeightings: CampaignInsights['ageRangeWeightings'];
};

function AgeWeightingsBar({ageRangeWeightings}: Props) {
  return (
    <div>
      <div className="flex h-4 md:h-7 w-full rounded-full">
        <div
          style={{width: `${ageRangeWeightings['13-18']}`}}
          className="h-full rounded-s-full border-y-2 border-l-2
          bg-[#53FFFF] border-[#53FFFF] bg-opacity-30"
        ></div>
        <div
          style={{width: `${ageRangeWeightings['19-25']}`}}
          className="h-full border-2
          bg-[#D3D2F6] border-[#D3D2F6] bg-opacity-30"
        ></div>
        <div
          style={{width: `${ageRangeWeightings['26-40']}`}}
          className="h-full border-2
          bg-[#FB8C5D] border-[#FB8C5D] bg-opacity-30"
        ></div>
        <div
          style={{width: `${ageRangeWeightings['40+']}`}}
          className="h-full rounded-e-full border-y-2 border-r-2
          bg-[#FD6779] border-[#FD6779] bg-opacity-30"
        ></div>
      </div>
    </div>
  );
}

export default AgeWeightingsBar;
