/* eslint-disable react/jsx-no-constructed-context-values */
import {useReducer} from 'react';

import UserContext from '../context/user.context';
import userReducer from '../reducer/user.reducer';

export default function UserProvider({children}: any) {
  const [user, userDispatch] = useReducer(userReducer, {});

  return (
    <UserContext.Provider value={{user, userDispatch}}>
      {children}
    </UserContext.Provider>
  );
}
