import React, {ReactNode} from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  borderColor?: string;
  bgColor?: string;
  title?: string;
};

function Card({children, className, title}: Props) {
  return (
    <div
      className={`rounded-lg border border-solid border-[#242932] bg-[#13161D] ${className}`}
    >
      {title ? <p className="pt-10 pl-10 text-base">{title}</p> : null}
      {children}
    </div>
  );
}

export default Card;
