import {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import GetCampaign from '../../../core/services/GetCampaign';
import {GetCampaignInsights} from '../../../core/services/GetCampaignInsights';
import UserContext from '../../../core/state/context/user.context';
import {Campaign} from '../../../core/types/campaign.type';
import {CampaignInsights} from '../../../core/types/campaignInsights.type';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

export default function InsightPage() {
  const routeParams = useParams();
  const campaignId = routeParams.campaignId;
  const insightId = routeParams.insightId;

  const {user} = useContext(UserContext);

  const [campaign, setCampaign] = useState<Campaign>(null!);
  const [campaignInsights, setCampaignInsights] = useState<CampaignInsights>(
    null!,
  );
  const [insight, setInsight] = useState<any>(null!);

  const token = localStorage.getItem('voop_token');

  useEffect(() => {
    if (campaignId) {
      GetCampaign(ENDPOINT, token, user.userId as string, campaignId).then(
        (response: Campaign) => {
          console.log('campaignId', campaignId);
          console.log('campaign', response);
          setCampaign(response);
        },
      );
      GetCampaignInsights(
        ENDPOINT,
        token,
        user.userId as string,
        campaignId,
      ).then((response: any) => {
        console.log('campaignId for insights', campaignId);
        console.log('insights', response);
        setCampaignInsights(response);

        campaignInsights.topInsights.filter((insight: any) => {
          if (insight.campaignQuestionHash === insightId) {
            setInsight(insight);
          }
        });
      });
    }
  }, []);

  return (
    <>
      <div className="dashboard campaign-dashboard">
        <p>{insight && insight.insight}</p>
      </div>
    </>
  );
}
