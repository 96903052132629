import SignupPage from '../views/pages/signup/SignupPage';
import LoginPage from '../views/pages/login/LoginPage';
import RecordVoopPage from '../views/pages/recordVoop/RecordVoop.page';

const PublicRoutes = [
  {
    path: '/',
    element: <SignupPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/voop-campaigns/:campaignId',
    element: <RecordVoopPage />,
  },
  // {
  //   path: '*',
  //   element: <SignupPage />,
  // },
];

export default PublicRoutes;
