import {Campaign} from '../types/campaign.type';
import {createApiRequest} from '../api/api';

export default async function GetCampaigns(
  endpoint: string,
  token: any,
  userId: string,
): Promise<Campaign[]> {
  const headers = {
    Authorization: 'Bearer ' + token,
  };
  const data = await createApiRequest(
    null,
    headers,
    'GET',
    `${endpoint}/user/${userId}/campaigns`,
  );
  return JSON.parse(data.body);
}
