import RecordStopIcon from '../../assets/icons/RecordStopIcon';
import CircleIcon from '../../assets/icons/CircleIcon';
import formatTime from '../../core/helpers/FormatTime';
import RecordSvg from '../../assets/icons/record.svg';
import EllipseSvg from '../../assets/icons/ellipse.svg';
import StopRecordSvg from '../../assets/icons/stopRecord.svg';

type Props = {
  isRecording: boolean;
  recordingTime: number;
  startRecording: () => void;
  stopRecording: () => void;
  recordingBlob: Blob | undefined;
  processVoop: () => Promise<void>;
};

function RecordingButton({
  isRecording,
  recordingTime,
  stopRecording,
  startRecording,
  recordingBlob,
  processVoop,
}: Props) {
  const onRecordingComplete = () => {
    stopRecording();
    // addAudioElement(recordingBlob);
  };

  return (
    <div>
      {isRecording && (
        <div
          className="relative cursor-pointer flex items-center justify-center"
          onClick={() => onRecordingComplete()}
        >
          <img src={StopRecordSvg} className="animate-pulse w-24 xs:w-full" />
          <p className="absolute">{formatTime(recordingTime)}</p>
        </div>
      )}

      {!isRecording && !recordingBlob && (
        <div
          className="cursor-pointer bg-red-400"
          onClick={() => startRecording()}
        >
          <img src={RecordSvg} alt="" className="w-24 xs:w-full" />
        </div>
      )}

      {recordingBlob && !isRecording && (
        <div
          className="relative cursor-pointer flex items-center justify-center"
          onClick={() => processVoop()}
        >
          <img src={EllipseSvg} className="w-24 xs:w-full" />
          <p className="absolute text-base xs:text-xl">Send</p>
        </div>
      )}
    </div>
  );
}

export default RecordingButton;
