import HomePage from '../views/pages/home/Home.page';
import AccountPage from '../views/pages/account/Account.page';
import CampaignPage from '../views/pages/campaign/Campaign.page';
import InsightPage from '../views/pages/insight/Insight.page';
import CreateCampaignPage from '../views/pages/createCampaign/CreateCampaign.page';
import RecordVoopPage from '../views/pages/recordVoop/RecordVoop.page';
import VoopsPage from '../views/pages/voops/Voops.page';

const AuthenticatedRoutes = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/account',
    element: <AccountPage />,
  },
  {
    path: '/campaigns/:campaignId',
    element: <CampaignPage />,
  },
  {
    path: '/campaigns/:campaignId/voops',
    element: <VoopsPage />,
  },
  {
    path: '/campaigns/:campaignId/insights/:insightId',
    element: <InsightPage />,
  },
  {
    path: '/create-campaign',
    element: <CreateCampaignPage />,
  },
  {
    path: '/voop-campaigns/:campaignId',
    element: <RecordVoopPage />,
  },
];

export default AuthenticatedRoutes;
