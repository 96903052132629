import {createApiRequest} from '../api/api';
import {CampaignInsights} from '../types/campaignInsights.type';

export async function GetCampaignInsights(
  endpoint: string,
  token: any,
  userId: string,
  campaignId: string,
): Promise<CampaignInsights> {
  const headers = {
    Authorization: 'Bearer ' + token,
  };
  const data = await createApiRequest(
    null,
    headers,
    'GET',
    `${endpoint}/user/${userId}/campaign/${campaignId}/insights`,
  );

  return JSON.parse(data.body);
}
