import {createApiRequest} from '../api/api';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

type User = {
  name: string;
  company: string;
  game: string;
  communitySize: number;
  email: string;
};

export default async function PostUser(user: User) {
  // post user
  const headers = {};
  return await createApiRequest(user, headers, 'POST', `${ENDPOINT}/user`);
}
