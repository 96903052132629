import {
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import {Dialog, Transition} from '@headlessui/react';
import {PlayIcon} from '@heroicons/react/20/solid';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Pagination from '../../components/Pagination';

import {Campaign} from '../../../core/types/campaign.type';
// import {
//   Campaign,
//   CampaignDatabaseItem,
//   CampaignInsightsResponse,
// } from '@voop/types';
import LoadingScreen from '../../components/LoadingScreen';
import {CampaignInsights} from '../../../core/types/campaignInsights.type';
import {GetCampaignInsights} from '../../../core/services/GetCampaignInsights';
import GetVoops from '../../../core/services/GetVoops';
import GetCampaign from '../../../core/services/GetCampaign';
import UserContext from '../../../core/state/context/user.context';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;
/**
 * Campaign Screen
 *
 * @constructor
 */
export default function VoopsPage() {
  const routeParams = useParams();
  const navigate = useNavigate();
  const campaignId = routeParams.campaignId;

  const {user} = useContext(UserContext);

  const [campaign, setCampaign] = useState<Campaign>(null!);
  const [open, setOpen] = useState(false);
  const [activeVoop, setActiveVoop] = useState<any>(null!);

  const [campaignInsights, setCampaignInsights] = useState<CampaignInsights>(
    null!,
  );
  const [campaignSummary, setCampaignSummary] = useState<any>(null!);
  const [voops, setVoops] = useState<any>(null!);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isCampaignReady, setIsCampaignReady] = useState<boolean>(false);
  const [isCampaignInsightsReady, setIsCampaignInsightsReady] =
    useState<boolean>(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);

  const cancelButtonRef = useRef(null);
  const toggleShareModal = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };

  useEffect(() => {
    setLoading(true);
    const prepareData = async () => {
      const token = localStorage.getItem('voop_token');
      const result = await GetVoops(
        ENDPOINT,
        token,
        user.userId as string,
        campaignId,
      );
      setVoops(result);
      setLoading(false);
    };
    prepareData();
  }, []);

  useEffect(() => {
    if (isCampaignReady && isCampaignInsightsReady) {
      setLoading(false);
    }
  }, [isCampaignReady, isCampaignInsightsReady]);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="dashboard campaign-dashboard">
        <div className="row px-9">
          <div className="sub-row pt-10">
            <Link
              className="font-medium text-lg leading-none tracking-widest"
              to={`/campaigns/${campaignId}`}
            >
              &#60; Back to Insights
            </Link>
          </div>

          <div className="">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h2 className="mt-10 text-md leading-none">
                  VOOPS for campaign: <br />
                  <span className="font-bold text-xl mt-2 block">
                    How do you feel about the current state of shotguns?
                  </span>
                </h2>
                <p className="mt-6 text-sm text-white">
                  Below are the voops for your campaign.
                </p>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-100">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                        >
                          Preview
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                        >
                          File Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                        >
                          Quick Insights
                        </th>
                        <th
                          scope="col"
                          className="relative py-3 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">View</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 bg-transparent">
                      {voops && voops.length
                        ? voops.map(
                            (voop: {
                              filename: any;
                              uuid: any;
                              transcription: any;
                              campaignId: any;
                              sentiment: any;
                            }) => (
                              <tr key={voop.uuid}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
                                  {voop.uuid}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {voop.transcription.substring(0, 50)}...
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {voop.filename}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                  <p
                                    className="text-indigo-600 hover:text-indigo-900 text-base cursor-pointer"
                                    onClick={() => {
                                      setOpen(true);
                                      setActiveVoop(voop);
                                    }}
                                  >
                                    View
                                  </p>
                                </td>
                              </tr>
                            ),
                          )
                        : 'No Voops found'}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-[9999]"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-xl font-semibold leading-6 text-gray-900 mb-3"
                          >
                            Voop Playback
                          </Dialog.Title>

                          <div className="flex gap-3 items-center mt-3">
                            {/* <PlayIcon className="text-gray-700 w-6 cursor-pointer" />
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                              <div
                                className="bg-blue-600 h-2.5 rounded-full"
                                style={{width: '45%'}}
                              />
                            </div> */}

                            {activeVoop?.filename ? (
                              <AudioPlayer
                                src={`https://dev-audio-data-converted.s3.eu-west-2.amazonaws.com/${activeVoop.filename}`}
                                customAdditionalControls={[]}
                                showSkipControls={false}
                                showJumpControls={false}
                              />
                            ) : null}
                          </div>
                          <p className="text-gray-900 font-bold mt-3 text-base">
                            Transcription
                          </p>
                          <p className="text-sm text-gray-500 mt-3">
                            {activeVoop?.transcription}
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 flex items-center justify-center">
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          Close
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  );
}
