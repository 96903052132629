import {createApiRequest} from '../api/api';

const ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

type LoginUser = {
  email: string;
  password: string;
};

export default async function PostJwtToken(loginUser: LoginUser) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let result;
  try {
    result = await createApiRequest(
      JSON.stringify(loginUser),
      headers,
      'POST',
      `${ENDPOINT}/user/token`,
    );
  } catch (e) {
    result.token = null;
  }
  console.log(result);
  return result;
}
