import {useEffect, useState, useContext} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import './App.scss';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import PublicRoutes from './routes/PublicRoutes';
import Navbar from './views/components/navbar/Navbar';
import PostTokenValidation from './core/services/PostTokenValidation';
import LoadingScreen from './views/components/LoadingScreen';
import UserContext from './core/state/context/user.context';
import {UPDATEUSER, User} from './core/types/user.type';

function App() {
  const [isReady, setReady] = useState(false);
  const {user, userDispatch} = useContext(UserContext);

  useEffect(() => {
    checkUserLogged();
  }, []);

  const checkUserLogged = async () => {
    const voopToken = localStorage.getItem('voop_token');
    console.log('check user logged: ', JSON.stringify(user));
    try {
      if (voopToken !== null) {
        const result = await PostTokenValidation({
          token: voopToken,
        });
        if (result.tokenValidation) {
          const userPayload: User = {
            userId: result.userId,
            email: result.email,
            isAuthenticated: true,
          };
          console.log('userPayload: ', userPayload);
          userDispatch({
            type: UPDATEUSER,
            payload: userPayload,
          });
        }
      }
    } catch (e) {
      console.log('token expired.');
    }

    setReady(true);
  };

  return (
    <>
      {!isReady ? (
        <LoadingScreen />
      ) : (
        <>
          <Navbar />
          <Routes>
            {!user.isAuthenticated ? (
              <>
                {PublicRoutes.map((route: any, index: number) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                ))}
              </>
            ) : (
              <>
                {AuthenticatedRoutes.map((route: any, index: number) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                ))}
              </>
            )}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
