import React, {useState} from 'react';
import {Button, Input} from '@mui/material';
import PostUser from '../../../core/services/PostUser';

export default function LoginScreen() {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [game, setGame] = useState('');
  const [communitySize, setCommunitySize] = useState(0);
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const signUpData = {
      name,
      company,
      game,
      communitySize,
      email,
    };

    console.log('sign up data', signUpData);

    if (!signUpData.name || !signUpData.company || !signUpData.email) {
      alert('Please fill in all required fields');
      return;
    } else {
      setSubmitting(true);

      const response = await PostUser(signUpData);

      if (response !== 'error') {
        alert(`Sign Up Successful`);
      } else {
        alert('There was an error signing up');
      }
      setSubmitting(false);
    }
  };

  return (
    <div className="login-screen">
      <img alt="" className="login-screen__grid" src="grid-gradient.svg" />

      <div className="login-screen__left-column">
        <img alt="" className="login-screen__laptop" src="laptop.png" />
      </div>

      <div className="login-screen__right-column">
        <div className="login-screen__sign-up">
          <h1 className="login-screen__sign-up-title">Sign up</h1>

          <form onSubmit={handleSignUp}>
            <div className="flex flex-col">
              <span className="login-screen__sign-up-label">Name*</span>
              <Input
                required
                className="login-screen__input"
                style={{backgroundColor: 'white'}}
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
              />
            </div>

            <div className="flex flex-col">
              <span className="login-screen__sign-up-label">Company Name*</span>
              <Input
                required
                className="login-screen__input"
                style={{backgroundColor: 'white'}}
                value={company}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompany(event.target.value);
                }}
              />
            </div>

            <div className="flex flex-col">
              <span className="login-screen__sign-up-label">Game*</span>
              <Input
                required
                className="login-screen__input"
                style={{backgroundColor: 'white'}}
                value={game}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGame(event.target.value);
                }}
              />
            </div>

            <div className="flex flex-col">
              <span className="login-screen__sign-up-label">
                Community Size*
              </span>
              <Input
                required
                className="login-screen__input"
                style={{backgroundColor: 'white'}}
                value={communitySize}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCommunitySize(parseInt(event.target.value));
                }}
              />
            </div>

            <div className="flex flex-col">
              <span className="login-screen__sign-up-label">
                Company Email*
              </span>
              <Input
                required
                className="login-screen__input mb-10"
                style={{backgroundColor: 'white'}}
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
              />
            </div>

            <Button
              className="voop-button"
              disabled={submitting}
              variant="contained"
              type="submit"
            >
              Sign up
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
