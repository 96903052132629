import axios from 'axios';

export async function createApiRequest(
  body: any,
  headers: any,
  method: string,
  url: string,
): Promise<any> {
  const response = await axios({
    method,
    url,
    data: body,
    headers,
  });
  return response.data;
}

export async function createFormApiRequest(
  body: any,
  headers: any,
  method: string,
  url: string,
): Promise<any> {
  const response = await axios({
    method,
    url,
    data: body,
    headers,
  });

  return response.data;
}
