import React from 'react';

type Props = {};

function PasswordTab({}: Props) {
  return (
    <div className="w-[400px] pt-10">
      <p className="pt-4 pb-1 text-sm">Current password</p>
      <input className="input text-base"></input>
      <p className="pt-4 pb-1 text-sm">Enter your new password</p>
      <input className="input text-base"></input>
      <p className="pt-4 pb-1 text-sm">Re-enter your new password</p>
      <input className="input text-base"></input>
      <div className="pt-5">
        <button className="voop-button">Change password</button>
      </div>
    </div>
  );
}

export default PasswordTab;
