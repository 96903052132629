import {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import Card from '../../components/Card';
import {Button, Input} from '@mui/material';
import GetJwtToken from '../../../core/services/PostJwtToken';
import LoadingScreen from '../../components/LoadingScreen';
import UserContext from '../../../core/state/context/user.context';
import {UPDATEUSER, User} from '../../../core/types/user.type';

type Props = {};

function LoginPage({}: Props) {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {userDispatch} = useContext(UserContext);

  const dispatchUser = (userInfo: User) => {
    console.log('loginPage - userInfo: ', userInfo);
    userDispatch({
      type: UPDATEUSER,
      payload: userInfo,
    });
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const result = await GetJwtToken({email, password});
    if (result.token !== null) {
      localStorage.setItem('voop_token', result.token);
      dispatchUser({email, userId: result.userId, isAuthenticated: true});
      navigate(`/home`);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      {isLoading && <LoadingScreen />}
      <Card className="w-[650px] h-[450px] bg-white px-10">
        <h2 className="text-[#0F0030] text-3xl">Log in to your Voop account</h2>

        <form onSubmit={handleLogin}>
          <div className="flex flex-col mt-10 mb-10">
            <label htmlFor="email" className="login-screen__sign-up-label">
              Email
            </label>
            <Input
              required
              id="email"
              name="email"
              className="login-screen__input"
              style={{backgroundColor: 'white'}}
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />

            <label htmlFor="password" className="login-screen__sign-up-label">
              Password
            </label>
            <Input
              required
              id="password"
              name="password"
              className="login-screen__input"
              style={{backgroundColor: 'white'}}
              type="password"
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
            />
          </div>

          <Button
            className="voop-button"
            disabled={isLoading}
            variant="contained"
            type="submit"
          >
            Log in
          </Button>
        </form>
      </Card>
    </div>
  );
}

export default LoginPage;
