import React, {useEffect, useContext} from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import {Button, Avatar, IconButton, Menu, MenuItem} from '@mui/material';
import UserContext from '../../../core/state/context/user.context';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {UPDATEUSER, User} from '../../../core/types/user.type';

export default function Navbar() {
  const navigate = useNavigate();

  const {user, userDispatch} = useContext(UserContext);

  const [showNavbar, setShowNavbar] = React.useState(true);
  const location = useLocation();

  const options = ['My Account', 'Log Out'];

  const handleLogin = () => {
    if (location.pathname === '/login') {
      navigate('/signup');
    } else {
      navigate('/login');
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem('voop_token');
    const userPayload: User = {
      userId: null,
      email: null,
      isAuthenticated: false,
    };
    userDispatch({
      type: UPDATEUSER,
      payload: userPayload,
    });

    navigate('/');
    window.location.reload();
  };

  const handleNavMenuClick = (option: string) => {
    switch (option) {
      case 'My Account':
        navigate('/account');
        break;
      case 'Log Out':
        logout();
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.includes('/voop-campaigns/')) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location]);

  return (
    <>
      {showNavbar && (
        <div className="navbar-container">
          <div className="navbar-padding"></div>
          <div className="navbar">
            <RouterLink to={`/`} color="inherit">
              <img src={`${process.env.REACT_APP_BASE_URL}/voop-logo.svg`} />
            </RouterLink>

            {!user.isAuthenticated ? (
              <Button
                className="voop-button"
                color="success"
                variant="contained"
                onClick={handleLogin}
              >
                {location.pathname === '/login' ? 'Sign Up' : 'Log in'}
              </Button>
            ) : (
              <div className="navbar__right-links">
                <Avatar sx={{width: 32, height: 32, bgcolor: '#000'}}>A</Avatar>

                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <KeyboardArrowDownIcon htmlColor="#898A8D" />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {options.map(option => (
                    <MenuItem
                      key={option}
                      onClick={() => handleNavMenuClick(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
